let tabs__button = document.querySelectorAll('.schede .js-tabs-button');





// Define our viewportWidth variable
var viewportWidth;

// Set/update the viewportWidth value
var setViewportWidth = function () {
	viewportWidth = window.innerWidth || document.documentElement.clientWidth;
}

// Log the viewport width into the console
var logWidth = function () {
	if (viewportWidth < 768) {
		tabs__button.forEach(function (element,index) {
	
			element.addEventListener('click', function (e) {
				let toClick = element.nextElementSibling
				toClick.click()
			})
		})
	}
}

// Set our initial width and log it
setViewportWidth();
logWidth();

// On resize events, recalculate and log
window.addEventListener('resize', function () {
	setViewportWidth();
	logWidth();
}, false);