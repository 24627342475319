//import css
import "./scss/main.scss";

//import components js
import "./components/cols-2-icons/cols-2-icons.js";
import "./components/cols-2-img/cols-2-img.js";
import "./components/contact/contact.js";
import "./components/faq/faq.js";
import "./components/footer/footer.js";
import "./components/header/header.js";
import "./components/hero-video/hero-video.js";
import "./components/icon/icon.js";
import "./components/img-3/img-3.js";
import "./components/schede/schede.js";
import "./components/section-number/section-number.js";
import "./components/slider-2-cols/slider-2-cols.js";
import "./components/tab-icon/tab-icon.js";
import "./components/timeline/timeline.js";

import "./js/lazyload";
